// colors
$body-size: 14px;

//$body-background-color: #f8f8f8;
$body-background-color: lighten(#EEE5DE, 4);
$primary: #02c874;
$danger: #e84f4f;
$link: #00baee;
$warning: #FFA824;

// navigation
$navbar-breakpoint: 768px;


// layout
$section-padding: 0 1.5rem;