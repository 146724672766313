@import 'bulma_overrides';
@import "!~bulma/bulma";


.main-navbar {
  margin-bottom: 1em;
  background: $body-background-color;
}

.edit-item {
  background-color: white;
}


.card {
  border-radius: $radius;
  margin-bottom: 2em;
}

.item-image {
  border: 1px solid $border;
}

.logout-button {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.last-element {
  margin-bottom: 0;
}

.action-buttons {
  margin-bottom: 1em;
}

.has-bottom-spacing {
  margin-bottom: 1em;
}